import React, { Component } from 'react';
import { Container, Header, Ref, Modal, Popup, Icon } from 'semantic-ui-react';
import ReactGA from 'react-ga';

const konami = 'ArrowUpArrowUpArrowDownArrowDownArrowLeftArrowRightArrowLeftArrowRight'.toUpperCase();
const fileNames = ['hamlet.txt', 'juliuscaesar.txt', 'kinglear.txt', 'midsummernightsdream.txt', 'othello.txt', 'romeoandjuliet.txt'];
const keyStrings = ['TOBEORNOTTOBE', 'BEWARETHEIDESOFMARCH', 'NOTHINGWILLCOMEOFNOTHING', 'LORDWHATFOOLSTHESEMORTALSBE', 'TISNEITHERHERENORTHERE', 'THUSWITHAKISSIDIE'];

class Writer extends Component {
  allowKey = true;
  constructor(props) {
    super(props);
    this.state = {
      currentText: '',
      characterStream: '',
      count: 0
    };
  }

  componentDidMount() {
    this.loadText();
    if (this.textArea) {
      this.textArea.focus();
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate(prevProps, prevState) {
    const { keyStringFound, reachedEnd, currentText, fileUrl } = this.props;
    if (keyStringFound && !prevState.keyStringFound) {
      ReactGA.event({
        category: 'Shakespeare',
        action: 'Entered Key Phrase',
        value: currentText.length,
        label: fileUrl
      });
    }
    if (reachedEnd && !prevState.reachedEnd) {
      ReactGA.event({
        category: 'Shakespeare',
        action: 'Reached End',
        label: fileUrl
      });
    }
  }

  loadText = () => {
    const textIndex = Math.floor(Math.random() * fileNames.length);
    const fileName = fileNames[textIndex];
    const fileUrl = `/files/${fileName}`;
    this.setState({
      fileUrl
    });
    fetch(fileUrl)
     .then(r => r.text() )
     .then((t) => {
       const rawTextByWord = t.split('\n');
       this.setState({
         rawTextByWord,
         keyString: keyStrings[textIndex]
       }, () => {
         document.body.onkeydown = this.handleKeyDown;
       })
     });
  }

  handleKeyPress = (event, data) => {
    event.preventDefault();
    event.stopPropagation();
    const { rawTextByWord } = this.state;
    if (!rawTextByWord) {
      return;
    }
    const char = event.key.toUpperCase();
    this.setState(prevState => {
      const characterStream = (prevState.characterStream += char).substring((prevState.characterStream += char).length - 100).trim();
      const count = prevState.count;
      if (prevState.rawTextByWord.length > count) {
        const currentText = prevState.currentText + prevState.rawTextByWord[count] + '\n';
        const newState = {
          characterStream,
          count: count + 1,
          currentText
        }
        if (characterStream.includes(prevState.keyString)) {
          newState.keyStringFound = prevState.keyString;
        }
        if (characterStream.includes(konami)) {
          newState.keyStringFound = 'KONAMI';
        }
        return newState;
      }
      return {
        prevState,
        reachedEnd: true
      };
    });
  }

  render() {
    const { currentText, keyStringFound, reachedEnd } = this.state;
    return [
      <Popup
        key='help'
        basic
        hoverable
        trigger={(
          <Icon
            style={{ position: 'fixed', top: 10, right: 10 }}
            name='question circle outline'
          />
        )}
        content={(
          <div style={{ display: 'inline-block', fontSize: '0.8em' }}>
            <span>Brought to you by <a href='https://betareader.io' target='_blank' rel='noopener noreferrer'>BetaReader.io</a></span><br/>
            <span>We use <a href='https://tools.google.com/dlpage/gaoptout' target='_blank' rel='noopener noreferrer'>Google Analytics</a> to understand how people use this site.</span>
          </div>
        )}
      />,
      <Container key='container' text style={{ display: 'flex', flexDirection: 'column', height: '100vh', paddingTop: '1em' }}>
        <Header style={{ float: 'right' }} content={'Shakespeare Writer'} subheader={'Write like Shakespeare'}/>
        {
          keyStringFound && (
            <Modal open={true} size='small'>
              <Modal.Header>You are a great writer!</Modal.Header>
              <Modal.Content>
                <p>
                  And you know it!
                </p>
                <p>
                  As a reward, we'd like to offer you a special treat. Use coupon code <strong>{keyStringFound}</strong> when you upgrade to a monthly plan on <a href='https://betareader.io&utm_medium=link&utm_source=spwr&utm_campaign=spwr_easter_egg' target='_blank' rel='noopener noreferrer'>BetaReader.io</a>, and we'll give you the first month for free.
                </p>
                <p>
                  Create your free account <a href='https://app.betareader.io/signup?target=https%3A%2F%2Fapp.betareader.io%2Fproducts&utm_medium=link&utm_source=spwr&utm_campaign=spwr_easter_egg' target='betareader.io_writer'>here</a>, and then upgrade using the code!
                </p>
                <p>
                  <em>
                    PS. Hurry up, because this particular code (<strong>{keyStringFound}</strong>) can only be used once. If someone was here before you, it might be used already. DS.
                  </em>
                </p>
              </Modal.Content>
            </Modal>
          )
        }
        {
          reachedEnd && (
            <Modal open={true} size='small'>
              <Modal.Header>Congratulations!</Modal.Header>
              <Modal.Content image>
                <Modal.Description>
                  <p>You finished your first Shakespeare manuscript. That wasn't so hard, was it?</p>
                  <p>If you want to test it (or your other WIPs), get yourself a <a href='https://app.betareader.io/signup?utm_medium=link&utm_source=spwr&utm_campaign=shakespeare_completed' target='betareader.io_writer'>BetaReader.io</a> account!</p>
                </Modal.Description>
              </Modal.Content>
            </Modal>
          )
        }
        <Ref innerRef={textArea => this.textArea = textArea}>
          <textarea
            style={{
              width: '100%',
              border: 'none',
              fontFamily: 'monospace',
              background: 'transparent',
              outline: 'none!important',
              resize: 'none',
              height: 'calc(100% - 45px)',
              overflow: 'hidden'
            }}
            value={currentText}
            onKeyUp={this.handleKeyPress}
            onChange={() => {}}
          />
        </Ref>
      </Container>
    ];
  }
}

export default Writer;
