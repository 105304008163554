import React, { Component } from 'react';
import './App.css';
import './semantic/dist/semantic.min.css';
import ReactGA from 'react-ga';
import Writer from './containers/writer';
ReactGA.initialize('UA-101984305-5');

class App extends Component {
  render() {
    return (
      <Writer />
    );
  }
}

export default App;
